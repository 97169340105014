import logo from '../Assets/logo.png';
import '../Styles/App.css';
import React, { useState, useEffect } from 'react';
import {
    Button, Box, useMediaQuery, Dialog, Typography, Drawer,
    Accordion, AccordionDetails, AccordionSummary, Divider,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    Stack, Paper, Snackbar, Avatar, TextField,
    IconButton
} from '@mui/material';
import {
    Cancel, Save, Delete, Edit, Add, ContentCopy,
    CheckCircleOutline, ExpandMore, ErrorOutline, Flag
} from '@mui/icons-material';
import { useTheme, styled } from '@mui/material/styles';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { red, green, blue, grey } from '@mui/material/colors';

import { APIUrl, APIMainRequestPOST, APIMainRequestGET } from '../Manager/CommManager'
import STLViewer from '../Components/STLViewer'

const Plans = ({ getWitchPage }) => {
    // const navigate = useNavigate();

    const [jobid, setJobid] = useState('')
    const [productid, setProductid] = useState('')

    const [getFile1, setFile1] = useState('')
    const [getFile2, setFile2] = useState('')
    const [getFile3, setFile3] = useState('')
    const [getFile4, setFile4] = useState('')

    const getFittingResult = (jobID, productID) => {
        // /users/result/heatmap/:jobid 6761d9acf1a831a3ef9872de
        const setParams = {
            fileUrl: null
        }
        const makeProdRequest = `${APIUrl}/users/result/heatmap2/${jobID}/${productID}`
        console.log("DetailsMerchantLasts -> getStlFile -> setParams :" + JSON.stringify(setParams, null, 2));
        console.log("DetailsMerchantLasts -> getStlFile -> makeProdRequest :" + makeProdRequest);
        APIMainRequestGET(makeProdRequest, setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('DetailsMerchantLasts -> getStlFile -> Base 64 response : ' + JSON.stringify(myJson, null, 2))
            console.log("DetailsMerchantLasts -> getStlFile -> File 1 :", myJson.result[0].meshUrl);
            console.log("DetailsMerchantLasts -> getStlFile -> File 2 :", myJson.result[1].meshUrl);
            console.log("DetailsMerchantLasts -> getStlFile -> File 3 :", myJson.result[2].meshUrl);
            console.log("DetailsMerchantLasts -> getStlFile -> File 4 :", myJson.result[3].meshUrl);

            const url1 = getStlFile(myJson.result[0].meshUrl)
            const url2 = getStlFile(myJson.result[1].meshUrl)
            const url3 = getStlFile(myJson.result[2].meshUrl)
            const url4 = getStlFile(myJson.result[3].meshUrl)

            setFile1(url1)
            setFile2(url2)
            setFile3(url3)
            setFile4(url4)
            // setBase64StlFile(myJson.b64)
            // const extension = getFileExtension(url);
            // setFileExtension(extension)
            // console.log("DetailsMerchantLasts -> getStlFile -> Le fichier est de type :", extension);

        })
    }

    const getStlFile = (url) => {
        const setParams = {
            fileUrl: url
        }
        const makeProdRequest = `${APIUrl}/stlFile`
        console.log("DetailsMerchantLasts -> getStlFile -> setParams :" + JSON.stringify(setParams, null, 2));
        console.log("DetailsMerchantLasts -> getStlFile -> makeProdRequest :" + makeProdRequest);
        APIMainRequestPOST('/stlFile', setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('DetailsMerchantLasts -> getStlFile -> Base 64 response : ' + JSON.stringify(myJson, null, 2))
            return myJson.b64
            // setBase64StlFile(myJson.b64)
            // const extension = getFileExtension(url);
            // setFileExtension(extension)
            // console.log("DetailsMerchantLasts -> getStlFile -> Le fichier est de type :", extension);

        })

    }

    const sendForm = () => {
        getFittingResult(jobid, productid)
    }

    const getfieldValue = (e) => {
        const getValue = e.target.value
        const getID = e.target.id
        if (getID === 'jobID') {
            setJobid(getValue)
        } else {
            setProductid(getValue)
        }
        console.log(`Plans -> getfieldValue -> getID : ${getID} - getValue : ${getValue}`);

    }

    useEffect(() => {

        console.log('Plans -> useEffect -> ');


    }, []);


    return (

        <>
            <Stack direction='column' spacing={2}>
                <Stack direction='row' spacing={2}>
                    <TextField
                        label="JOB ID"
                        variant="outlined"
                        id='jobID'
                        value={jobid}
                        onChange={getfieldValue}
                        margin="normal"
                    />
                    <TextField
                        label="PRODUCT ID"
                        variant="outlined"
                        id='productID'
                        value={productid}
                        onChange={getfieldValue}
                        margin="normal"
                    />
                    <Button
                        sx={{
                            mt: 1, mr: 1,
                            borderColor: blue[800], borderStyle: 'solid',
                            borderWidth: 1, backgroundColor: grey[50], color: blue[600]
                        }}
                        onClick={sendForm}
                        variant="contained"
                        size="large">
                        Send
                    </Button>
                </Stack>
                <Stack direction='row' spacing={2}>
                    {
                        getFile1 !== '' ?
                            <>
                                <STLViewer base64String={getFile1} />
                                <STLViewer base64String={getFile2} />
                            </> : <Typography>No Data</Typography>
                    }

                </Stack>

                <Stack direction='row' spacing={2}>
                    {
                        getFile3 !== '' ?
                            <>
                                <STLViewer base64String={getFile3} />
                                <STLViewer base64String={getFile4} />
                            </> : <Typography>No Data</Typography>
                    }
                </Stack>

            </Stack>

        </>
    );
};

export default Plans;